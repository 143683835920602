import { Injectable, OnDestroy } from '@angular/core'
import { Subject, BehaviorSubject, fromEvent } from 'rxjs'
import { takeUntil, debounceTime } from 'rxjs/operators'
import { Router } from '@angular/router'

// Menu
export interface Menu {
  headTitle1?: string
  headTitle2?: string
  path?: string
  title?: string
  icon?: string
  type?: string
  badgeType?: string
  badgeValue?: string
  active?: boolean
  bookmark?: boolean
  children?: Menu[]
}

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject()
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  )

  // Search Box
  public search: boolean = true

  // Language
  public language: boolean = false

  // Mega Menu
  public megaMenu: boolean = false
  public levelMenu: boolean = false
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true

  // Full screen
  public fullScreen: boolean = false

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth)
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth)
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true
          this.megaMenu = false
          this.levelMenu = false
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true
        }
      })
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true
        this.megaMenu = false
        this.levelMenu = false
      })
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete()
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width)
  }

  MENUITEMS: Menu[] = [
    { title: 'Perfil', icon: 'user', type: 'link', path: '/profile' },
    {
      title: 'Sidebar.Profile',
      icon: 'bell',
      type: 'link',
      path: '/notifications'
    },
    {
      title: 'Sidebar.Dashboard',
      icon: 'copy',
      type: 'link',
      path: '/home'
    },
    {
      title: 'Sidebar.Recorridos',
      icon: 'copy',
      type: 'link',
      path: '/tours'
    },
    {
      title: 'Sidebar.Entregas',
      icon: 'copy',
      type: 'link',
      path: '/deliveries'
    },
    {
      title: 'Sidebar.Vehículos',
      icon: 'copy',
      type: 'link',
      path: '/vehicles'
    },
    {
      title: 'Sidebar.Conductores',
      icon: 'copy',
      type: 'link',
      path: '/drivers'
    }
  ]

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS)
}
