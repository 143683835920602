import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragAndDropListComponent } from "./drag-and-drop-list.component";

@NgModule({
  declarations: [DragAndDropListComponent],
  imports: [CommonModule, DragDropModule],
  exports: [DragAndDropListComponent],
})
export class DragAndDropModule {}
