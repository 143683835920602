import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DriversGraphicService {
    private storedDataKey = 'drivers';

  
    saveData(data: any[]): void {
      localStorage.setItem(this.storedDataKey, JSON.stringify(data));
    }
  
    getData(): any[] {
      const data = localStorage.getItem(this.storedDataKey);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogout(): void {
        localStorage.removeItem(this.storedDataKey);
    }

   
}
