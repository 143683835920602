import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/service/auth.service";
@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(
    public router: Router,
    private auth_service: AuthService
    
    ) {}

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   // Guard for user is login or not
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   if (!user || user === null) {
  //     this.router.navigate(["/auth/login"]);
  //     return true;
  //   } else if (user) {
  //     if (!Object.keys(user).length) {
  //       this.router.navigate(["/auth/login"]);
  //       return true;
  //     }
  //   }
  //   return true;
  // }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Verificar si hay un token almacenado en el servicio AuthService
    const token = this.auth_service.getToken();

    if (!token) {
      // No hay token, redirigir a la página de inicio de sesión
      this.router.navigate(["/auth/login"]);
      return false;
    }

    // Puedes agregar lógica adicional aquí según tus necesidades

    return true;
  }

}
