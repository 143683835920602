import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeGraphicService {
    private storedDataKey = 'home';
    private graphicCost = 'cost';
    private period = 'perido';
    private totalCost = 'totalCost';
    private xAxisCost = 'xAxisCost';
    private kpiNewDeliveriesKey = 'new-deliveries';
    private kpiTotalDriversKey = 'total-drivers';
    private kpiTotalIncomesKey = 'total-incomes';
    private kpiNroMaintenancesKey = 'nro-maintenances';

    // Save the main data array
    saveData(data: any[]): void {
        localStorage.setItem(this.storedDataKey, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getData(): any[] {
        const data = localStorage.getItem(this.storedDataKey);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogout(): void {
        localStorage.removeItem(this.storedDataKey);
        this.clearAllKpis(); // Also clear all KPI data
    }

      // Save the main data array
    saveDataPeriodo(data:any): void {
        localStorage.setItem(this.period, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDataPeriodo(): any {
        const data = localStorage.getItem(this.period);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogoutPeriodo(): void {
        localStorage.removeItem(this.period);
        this.clearAllKpis(); // Also clear all KPI data
    }



    saveDataxAxisCost(data: any[]): void {
        localStorage.setItem(this.xAxisCost, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDataxAxisCost(): any[] {
        const data = localStorage.getItem(this.xAxisCost);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogoutxAxisCost(): void {
        localStorage.removeItem(this.xAxisCost);
        this.clearAllKpis(); // Also clear all KPI data
    }


    saveDatatotalCost(data: any[]): void {
        localStorage.setItem(this.totalCost, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDatatotalCost(): any[] {
        const data = localStorage.getItem(this.totalCost);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogouttotalCost(): void {
        localStorage.removeItem(this.totalCost);
        this.clearAllKpis(); // Also clear all KPI data
    }

















     // Save the main data array
     saveDataCost(data: any[]): void {
        localStorage.setItem(this.graphicCost, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDataCost(): any[] {
        const data = localStorage.getItem(this.graphicCost);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogoutCost(): void {
        localStorage.removeItem(this.graphicCost);
        this.clearAllKpis(); // Also clear all KPI data
    }

    // Save KPI data
    saveKpi(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieve KPI data
    getKpi(key: string): any {
        const kpi = localStorage.getItem(key);
        return kpi ? JSON.parse(kpi) : null;
    }

    // Clear KPI data
    clearKpi(key: string): void {
        localStorage.removeItem(key);
    }

    // Save specific KPI data
    saveNewDeliveriesKpi(value: any): void {
        this.saveKpi(this.kpiNewDeliveriesKey, value);
    }

    saveTotalDriversKpi(value: any): void {
        this.saveKpi(this.kpiTotalDriversKey, value);
    }

    saveTotalIncomesKpi(value: any): void {
        this.saveKpi(this.kpiTotalIncomesKey, value);
    }

    saveNroMaintenancesKpi(value: any): void {
        this.saveKpi(this.kpiNroMaintenancesKey, value);
    }

    // Retrieve specific KPI data
    getNewDeliveriesKpi(): any {
        return this.getKpi(this.kpiNewDeliveriesKey);
    }

    getTotalDriversKpi(): any {
        return this.getKpi(this.kpiTotalDriversKey);
    }

    getTotalIncomesKpi(): any {
        return this.getKpi(this.kpiTotalIncomesKey);
    }

    getNroMaintenancesKpi(): any {
        return this.getKpi(this.kpiNroMaintenancesKey);
    }

    // Clear all KPI data
    clearAllKpis(): void {
        this.clearKpi(this.kpiNewDeliveriesKey);
        this.clearKpi(this.kpiTotalDriversKey);
        this.clearKpi(this.kpiTotalIncomesKey);
        this.clearKpi(this.kpiNroMaintenancesKey);
    }

    
}
