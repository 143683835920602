export const vehicleTypes = {
  camiones_y_remolques: {
    name: {
      en: "Trucks and Trailers",
      es: "Camiones y Remolques",
    },
    imagePath: "assets/images/vehicles-logos/vehicles_camions_remol.png",
    previewPath:
      "assets/images/vehicles-preview/camiones_y_remolques_preview.png",
  },
  automovil_de_pasajeros: {
    name: {
      en: "Passenger Automobiles",
      es: "Automóvil de Pasajeros",
    },
    imagePath: "assets/images/vehicles-logos/automovil_pasajeros.png",
    previewPath:
      "assets/images/vehicles-preview/automoviles_pasajeros_preview.png",
  },
  automovil_minibuses: {
    name: {
      en: "Minibus Automobiles",
      es: "Automóvil Minibuses",
    },
    imagePath: "assets/images/vehicles-logos/vehicle_bus_mini_buses.png",
    previewPath:
      "assets/images/vehicles-preview/automoviles_minibuses_preview.png",
  },
  vehiculos_comerciales: {
    name: {
      en: "Vehicles",
      es: "Vehículos",
    },
    imagePath: "assets/images/vehicles-logos/vehiculos_comercial.png",
    previewPath:
      "assets/images/vehicles-preview/vehiculos_comerciales_preview.png",
  },
  vehiculos_especiales: {
    name: {
      en: "Special Vehicles",
      es: "Vehículos Especiales",
    },
    imagePath: "assets/images/vehicles-logos/vehicles_specials.png",
    previewPath:
      "assets/images/vehicles-preview/vehiculos_especiales_preview.png",
  },
  vehiculos_electricos: {
    name: {
      en: "Electric Vehicles",
      es: "Vehículos Eléctricos",
    },
    imagePath: "assets/images/vehicles-logos/vehicles_electris.png",
    previewPath:
      "assets/images/vehicles-preview/vehiculos_electricos_preview.png",
  },
  autobuses: {
    name: {
      en: "Buses",
      es: "Autobuses",
    },
    imagePath: "assets/images/vehicles-logos/vehicles_bus_mini_buses.png",
    previewPath: "assets/images/vehicles-preview/autobuses_preview.png",
  },
};
