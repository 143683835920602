import { Component, Input } from "@angular/core";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent {
  @Input() question: any;
  @Input() idQuestion: number;
  @Input() question_type: any;

  constructor() {}
}
