import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-question-form",
  templateUrl: "./custom-question-form.component.html",
  styleUrls: ["./custom-question-form.component.scss"],
})
export class CustomQuestionFormComponent implements OnInit {
  question: string = "";
  answerOptions: string[] = [
    "Definitivamente SI",
    "Probablemente SI",
    "No lo sé",
    "Probablemente NO",
    "Definitivamente NO",
  ];
  selectedAnswerType: string = "SI/NO";
  isWrittenAnswer: boolean = false;
  writtenAnswer: string;

  constructor() {}

  ngOnInit(): void {}

  addAnswerOption() {
    this.answerOptions.push("");
  }

  toggleWrittenAnswer() {
    this.isWrittenAnswer = !this.isWrittenAnswer;
  }

  removeAnswerOption(id: number) {
    // Remove the last answer option if there are more than 2
  }
}
