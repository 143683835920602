import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/service/auth.service";

@Component({
  selector: "app-dialog-logout",
  templateUrl: "./dialog-logout.html",
  styleUrls: ["./dialog-logout.component.scss"],
})
export class DialogLogoutComponent {
  constructor(
    private modalService: NgbModal,
    private auth_service: AuthService,


  ){

  }
  closeModal(){
    this.modalService.dismissAll();
  }

  closeSession(){
    this.closeModal();
    this.auth_service.logout();
  }

}
