import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: number): string {
    if (value === null || value === undefined) return '';
    
    // La entrada se asume que es en minutos, por lo tanto no necesitamos convertirla a segundos
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);
    
    // Aplica pad tanto a horas como a minutos
    return `${this.pad(hours)}h ${this.pad(minutes)}m`;
  }

  private pad(value: number): string {
    // Asegura que el valor tenga siempre dos dígitos
    return value < 10 ? `0${value}` : value.toString();
  }
}
