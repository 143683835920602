import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-vehicle-radio",
  templateUrl: "./vehicle-section-radio.component.html",
  styleUrls: ["./vehicle-section-radio.component.scss"],
})
export class VehicleSectionRadioComponent implements OnInit {
  @Input() tipo: string;
  @Input() isComplete: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  get estadoClase() {
    return this.isComplete ? "border-green-500" : "border-red-500";
  }
}
