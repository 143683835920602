import { Component, Input, Output, EventEmitter } from "@angular/core";
import { vehicleTypes } from "./vehicle-types";

@Component({
  selector: "app-type-vehicle",
  templateUrl: "./type-vehicle.component.html",
  styleUrls: ["./type-vehicle.component.scss"],
})
export class TypeVehicleComponent {
  @Input() vehicleName: string;
  @Input() isActive: boolean;
  @Output() vehiclePreviewPath: EventEmitter<string> =
    new EventEmitter<string>();

  vehicleInfo: {
    name: { en: string; es: string };
    imagePath: string;
    previewPath: string;
  };

  constructor() {}

  ngOnChanges() {
    const formattedVehicleName = this.formatVehicleName(this.vehicleName);
    this.vehicleInfo = vehicleTypes[formattedVehicleName];
  }

  handleClick() {
    this.vehiclePreviewPath.emit(this.vehicleInfo.previewPath);
  }

  private formatVehicleName(name: string): string {
    return name
      .toLowerCase()
      .replace(/\s+/g, "_")
      .replace(/[^\w\-]+/g, "");
  }
}
