import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AdminGuard } from './shared/guard/admin.guard'
import { HomeLayoutComponent } from './components/layout/home-layout/home-layout.component'

const routes: Routes = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'auth/sign-in' },
  { pathMatch: 'full', redirectTo: '/home', path: 'signed-in-redirect' },

  // Auth routes for guests
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./modules/auth/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./modules/auth/register/register.module').then(
            (m) => m.RegisterModule
          )
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./modules/auth/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule
          )
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./modules/auth/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          )
      }
    ]
  },

  // Private routes
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'maintenance',
        loadChildren:() =>
          import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule)
    
      }, 
      {
        path: 'tours',
        loadChildren: () =>
          import('./modules/tours/tours.module').then((m) => m.ToursModule)
      },
      {
        path: 'vehicles',
        loadChildren: () =>
          import('./modules/vehicles/vehicles.module').then(
            (m) => m.VehiclesModule
          )
      },
      {
        path: 'drivers',
        loadChildren: () =>
          import('./modules/drivers/drivers.module').then(
            (m) => m.DriversModule
          )
      },
      {
        path: 'deliveries',
        loadChildren: () =>
          import('./modules/deliveries/deliveries.module').then(
            (m) => m.DeliveriesModule
          )
      },
      {
        path: 'playground',
        loadChildren: () =>
          import('./modules/playground/playground.module').then(
            (m) => m.PlaygroundModule
          )
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/orders/orders.module').then(
            (m) => m.OrdersModule 
          )
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./modules/billing/billing.module').then(
            (m) => m.BillingModule
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
]

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    ]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
