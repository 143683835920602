<div
  class="grid bg-[#F1F5F9] transition-all duration-300"
  [ngClass]="{
    'grid-cols-[minmax(0px,_314px)_minmax(0px,_1fr)]': expanded,
    'grid-cols-[minmax(0px,_93px)_minmax(0px,_1fr)]': !expanded
  }"
>
  <aside class="relative h-screen bg-white rounded-r-md flex flex-col">
    <!-- Switch -->
    <div
      class="absolute top-[40px] z-10 -right-4 bg-[#F1F5F9] w-12 h-12 rounded-full flex items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        (click)="expanded = !expanded"
        [ngClass]="{ 'rotate-180': !expanded }"
        class="cursor-pointer transition-all duration-300"
      >
        <circle cx="17" cy="17" r="17" fill="#FF8407" />
        <path
          d="M19.3182 22.409L13.9091 16.9999L19.3182 11.5908"
          stroke="white"
          stroke-width="1.54545"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <!-- Logo -->
    <div class="relative mx-auto mt-5 w-full max-w-[200px] aspect-square transition-opacity duration-300 z-0">
      <img
        alt="logo"
        [ngClass]="{ 'opacity-0': !expanded }"
        src="../../../../assets/images/logo/login_icon.png"
        class="absolute inset-0 w-full h-full object-contain"
      />
    </div>
    

    <!-- Links -->
    <nav class="mt-[70px] px-[18px]">
      <ul class="flex flex-col gap-y-[10px]">
        <li
          [routerLink]="[link.path]"
          *ngFor="let link of navLinks"
          [ngClass]="{ 'bg-[rgba(255,_132,_7,_0.10)]': pathname === link.path }"
          class="cursor-pointer w-full px-4 h-[56px] text-[#3333338A] rounded-[10px] flex gap-x-[29px] overflow-hidden items-center hover:bg-[rgba(255,_132,_7,_0.10)] transition-colors duration-300 group"
        >
          <app-feather-icons
            [icon]="link.icon"
            [ngClass]="{ 'text-primary': pathname === link.path }"
            class="group-hover:text-primary transition-colors duration-300 w-6 h-6"
          >
          </app-feather-icons>
          <span
            [ngClass]="{
              'opacity-0 whitespace-nowrap': !expanded,
              'text-[#333333]': pathname === link.path
            }"
            class="group-hover:text-[#333333] transition-all duration-300 text-base font-medium"
          >
            {{ link.label | translate }}
          </span>
        </li>
      </ul>
    </nav>

    <div class="mt-auto mx-auto flex flex-col mb-2 gap-y-[38px]">
      <button
        [routerLink]="['/tours/new']"
        [ngClass]="{ 'w-[224px]': expanded, 'w-[44px]': !expanded }"
        class="btn rounded-[44px] p-[9px] btn-solid-primary transition-all duration-300"
      >
        <app-feather-icons icon="plus" *ngIf="!expanded" class="">
        </app-feather-icons>

        <span
          *ngIf="expanded"
          class="transition-opacity duration-300 whitespace-nowrap"
        >
          {{ 'Sidebar.NewTour' | translate }}
        </span>
      </button>

      <button
      
        [ngClass]="{ 'w-[224px]': expanded, 'w-[44px]': !expanded }"
        class="btn gap-x-[29px] rounded-[44px] p-[9px] text-[#3333338C] transition-all duration-300 border-transparent"
        type="button"
        (click)="logout()"
      >
        <app-feather-icons icon="log-out" class="fill-[#333333]">
        </app-feather-icons>

        <span
          *ngIf="expanded"
          class="transition-opacity duration-300 whitespace-nowrap"
        >
          {{ 'Sidebar.Logout' | translate }}
        </span>
      </button>
    </div>
  </aside>

  <main class="overflow-y-auto">
    <div class="h-screen flex flex-col flex-1 px-8 mx-auto">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
