import { Component, OnInit, Input } from "@angular/core";
import { vehicleTypesImages } from "./vehiclesimage";

@Component({
  selector: "app-vehicle-selector",
  templateUrl: "./vehicle-selector.component.html",
  styleUrls: ["./vehicle-selector.component.scss"],
})
export class VehicleSelectorComponent implements OnInit {
  @Input() vehicleBrand: string;
  @Input() vehicleType: string;
  @Input() vehicleModel: string;
  @Input() vehiclePlate: string;
  @Input() isActive: boolean;

  constructor() {}

  ngOnInit(): void {}

  getImagePath(vehicleType: string): string {
    return vehicleTypesImages[vehicleType] || "";
  }
}
