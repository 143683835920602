import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { DeliveriesService } from './deliveries.service';
import { VehicleService } from './vehicle.service';
import { DriversGraphicService } from './drivers.service';
import { HomeGraphicService } from './home.service';
import { TourGraphicService } from './tour.service';
import {MaintenanceGraphicsService} from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string | null = null;
  private tokenKey = 'accessToken';


  constructor(
    private router: Router ,
    private cookie_service: CookieService,
    private deliveryDataService: DeliveriesService,
    private vehicleDataService: VehicleService,
    private driveDataService : DriversGraphicService,
    private homeDataService: HomeGraphicService,
    private tourDataService: TourGraphicService,
    private maintenanceGraphicsService:MaintenanceGraphicsService
  ){
    this.token = this.cookie_service.get(this.tokenKey);
  }


  //Almacenamos
  setToken(token:string):void{
    this.token = token;
    this.cookie_service.set(this.tokenKey, token);
  }

  //Obtenomos
  getToken():string | null{
    return this.token;
  }

  //Decodificamos
  // getUserInfo(): any {
  //   if (this.token) {
  //     return jwt_decode(this.token);
  //   }
  //   return null;
  // }

  // Método para verificar si el usuario está autenticado
  isAuthenticated(): boolean {
    return !!this.token;
  }

  // Método para cerrar sesión (limpiar el token)
  logout() {
    this.token = null;
    this.cookie_service.delete(this.tokenKey);
    this.router.navigate(['/auth/login']); 
    this.deliveryDataService.clearDataOnLogout();
    this.vehicleDataService.clearDataOnLogout();
    this.vehicleDataService.clearDataOnLogout2();
    this.driveDataService.clearDataOnLogout();
    this.maintenanceGraphicsService.clearDataOnLogout();
    //Home
    this.homeDataService.clearAllKpis();
    this.homeDataService.clearDataOnLogout();
    this.homeDataService.clearDataOnLogoutCost();
    this.homeDataService.clearDataOnLogouttotalCost();
    this.homeDataService.clearDataOnLogoutPeriodo();
    this.homeDataService.clearDataOnLogoutxAxisCost();

    //Tours
    this.tourDataService.clearDataTour();
    this.tourDataService.clearDataOnLogout();
    this.tourDataService.clearDataOnLogoutgraphicEfficiencyKilometer();
    this.tourDataService.clearAllKpis();
    

  }

  
}
