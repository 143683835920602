import axios from 'axios';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, retry } from 'rxjs';
@Injectable({
  providedIn:'root'
})
export class DriversService {
   
  urlApiAnt = environment.api_ant
  constructor(private http:HttpClient){}
  
  //TODO: DRIVERS LOCATION
  createDataGeneral(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.urlApiAnt}geolocation/usersLocation`, data)
      .pipe(
        map((resp) => {
          console.log('Conductores Localizacion', resp);
          return resp;
        })
      );
    }

    //Obtener Notificaciones 
    getNotifandPackages(){
      return this.http
      .get<any>(`${this.urlApiAnt}geolocation/usersLocation`)
      .pipe(
        map((resp) => {
          console.log('Notificaciones ', resp);
          return resp;
        })
      );
    }



}