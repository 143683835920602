<div>
  <div
    *ngFor="let section of sections"
    [cdkDropListData]="section.questions"
    cdkDropList
    (cdkDropListDropped)="drop($event, section.questions)"
    [ngClass]="{
      'bg-red-400': section.name === 'Warning',
      'bg-yellow-400': section.name === 'Alert',
      'bg-green-400': section.name === 'Self'
    }"
    class="example-list"
  >
    <h2 class="px-6 py-4">{{ section.name }}</h2>
    <div *ngFor="let question of section.questions" class="example-box" cdkDrag>
      {{ question }}
    </div>
  </div>
</div>
