<!-- Page Header Start-->
<div class="page-header h-[79px]" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->