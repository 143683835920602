export const vehicleTypesImages = {
  automovil_de_pasajeros:
    "assets/images/vehicles-logos/automovil_pasajeros.png",
  automovil_minibuses:
    "assets/images/vehicles-logos/vehicles_bus_mini_buses.png",
  camiones_y_remolques:
    "assets/images/vehicles-logos/vehicles_camions_remol.png",
  vehicles_comerciales: "assets/images/vehicles-logos/vehicles_comercial.png",
  vehicles_electricos: "assets/images/vehicles-logos/vehicles_electris.png",
  vehicles_especiales: "assets/images/vehicles-logos/vehicles_specials.png",
  vehicles_utilitarios: "assets/images/vehicles-logos/vehicles_utils.png",
};
