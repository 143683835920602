import { Injectable } from '@angular/core';
import { GetEficienciaRecorridosResponse } from '../api/charts/eficiencia-recorridos';
import { GetEficienciaKmResponse } from '../api/charts/eficiencia-km';
import { GetAllRecordsByFilterNodeResponse } from '../api/deliveries/recordsbyfilter';

@Injectable({
  providedIn: 'root'
})
export class TourGraphicService {
    private graphicEfficiencyTime = 'efficiecy-time';
    private graphicEfficiencyKilometer = 'efficieny-kilometer';
    private dataTours = 'tours';
    private kpirouteCompleted = 'route-complete';
    private kpiroutePending = 'route-pending';
    private kpipackageCompleted = 'package-complete';
    private kpipackagePending = 'package-pending';

    // Save the main data array
    saveDatagraphicEfficiencyTime(data: GetEficienciaRecorridosResponse): void {
        localStorage.setItem(this.graphicEfficiencyTime, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDatagraphicEfficiencyTime():GetEficienciaRecorridosResponse {
        const data = localStorage.getItem(this.graphicEfficiencyTime);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogout(): void {
        localStorage.removeItem(this.graphicEfficiencyTime);
        this.clearAllKpis(); // Also clear all KPI data
    }



    // Save the main data array
    saveDatagraphicEfficiencyKilometer(data: GetEficienciaKmResponse): void {
        localStorage.setItem(this.graphicEfficiencyKilometer, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDatagraphicEfficiencyKilometer(): GetEficienciaKmResponse {
        const data = localStorage.getItem(this.graphicEfficiencyKilometer);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataOnLogoutgraphicEfficiencyKilometer(): void {
        localStorage.removeItem(this.graphicEfficiencyKilometer);
        this.clearAllKpis(); // Also clear all KPI data
    }



    saveDataTour(data: GetAllRecordsByFilterNodeResponse): void {
        localStorage.setItem(this.dataTours, JSON.stringify(data));
    }
  
    // Retrieve the main data array
    getDataTour(): GetAllRecordsByFilterNodeResponse {
        const data = localStorage.getItem(this.dataTours);
        return data ? JSON.parse(data) : [];
    }

    // Clear the main data on logout
    clearDataTour(): void {
        localStorage.removeItem(this.dataTours);
        this.clearAllKpis(); // Also clear all KPI data
    }

    // Save KPI data
    saveKpi(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieve KPI data
    getKpi(key: string): any {
        const kpi = localStorage.getItem(key);
        return kpi ? JSON.parse(kpi) : null;
    }

    // Clear KPI data
    clearKpi(key: string): void {
        localStorage.removeItem(key);
    }

    // Save specific KPI data
    savekpirouteCompleted(value: any): void {
        this.saveKpi(this.kpirouteCompleted, value);
    }

    savekpiroutePending(value: any): void {
        this.saveKpi(this.kpiroutePending, value);
    }

    savekpipackageCompleted(value: any): void {
        this.saveKpi(this.kpipackageCompleted, value);
    }

    savekpipackagePending(value: any): void {
        this.saveKpi(this.kpipackagePending, value);
    }

    // Retrieve specific KPI data
    getkpirouteCompleted(): any {
        return this.getKpi(this.kpirouteCompleted);
    }

    getkpiroutePending(): any {
        return this.getKpi(this.kpiroutePending);
    }

    getkpipackageCompleted(): any {
        return this.getKpi(this.kpipackageCompleted);
    }

    getkpipackagePending(): any {
        return this.getKpi(this.kpipackagePending);
    }

    // Clear all KPI data
    clearAllKpis(): void {
        this.clearKpi(this.kpipackageCompleted);
        this.clearKpi(this.kpipackagePending);
        this.clearKpi(this.kpirouteCompleted);
        this.clearKpi(this.kpiroutePending);
    }

    
}
