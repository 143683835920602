<div class="modal modal-right" tabindex="-1" role="dialog">
  <div>
    <div>
    

      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="flex justify-end p-4">
          <button class="text-gray-500 hover:text-gray-700 focus:outline-none" (click)="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="p-6">
          <p class="text-gray-500 font-medium text-lg uppercase">¿Desea cerrar la sesión?</p>
        </div>
        <div class="flex justify-end px-6 pb-6 space-x-3">
          <button class="w-1/3 h-10 border border-gray-300 rounded-md text-base text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" (click)="closeModal()">Cancelar</button>
          <button class="w-1/3 h-10 border border-transparent rounded-md text-base text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" (click)="closeSession()">Sí</button>
        </div>
      </div>
     
  </div>
</div>