import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {
    private storedDataKey = 'vehicleGraphic01';
    private storedDataKey2 = 'vehicleGraphic02';

  
    saveData(data: any[]): void {
      localStorage.setItem(this.storedDataKey, JSON.stringify(data));
    }
  
    getData(): any[] {
      const data = localStorage.getItem(this.storedDataKey);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogout(): void {
        localStorage.removeItem(this.storedDataKey);
    }

      saveData2(data: any[]): void {
        localStorage.setItem(this.storedDataKey2, JSON.stringify(data));
      }
    
      getData2(): any[] {
        const data = localStorage.getItem(this.storedDataKey2);
        return data ? JSON.parse(data) : [];
      }
  
      clearDataOnLogout2(): void {
          localStorage.removeItem(this.storedDataKey2);
      }
}
