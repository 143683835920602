import { Component } from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-drag-and-drop-list",
  templateUrl: "./drag-and-drop-list.component.html",
  styleUrls: ["./drag-and-drop-list.component.scss"],
})
export class DragAndDropListComponent {
  sections = [
    {
      name: "Warning",
      questions: [
        "El nivel de aceite de motor es correcto?",
        "El nivel del refrigerante es el correcto?",
        "El ventilador del motor (fan clutch) presenta daño?",
      ],
    },
    {
      name: "Alert",
      questions: [
        "Visualizas fuga de combustible?",
        "La carga de la batería es la correcta?",
        "Visualizas fuga de refrigerante?",
      ],
    },
    {
      name: "Self",
      questions: [
        "Validaste que todas las tapas estén bien colocadas y en buen estado? (aceite motor, aceite dirección, tapón radiador)",
        "Las bandas de motor están colocadas correctamente (tensión adecuada)?",
        "La bayoneta del aceite de motor está colocada adecuadamente?",
      ],
    },
  ];

  drop(event: CdkDragDrop<string[]>, targetQuestions: string[]) {
    if (event.previousContainer !== event.container) {
      const movedItem = event.previousContainer.data[event.previousIndex];
      targetQuestions.splice(event.currentIndex, 0, movedItem);
      event.previousContainer.data.splice(event.previousIndex, 1);
    }
  }
}
