import axios from 'axios';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, retry } from 'rxjs';
import { start } from 'repl';
@Injectable({
  providedIn:'root'
})
export class maintenanceService {
   
  urlApi = environment.api_gugu
  urlApiAnt = environment.api_ant
  constructor(private http:HttpClient){}
  //TODO:Listado de Mantenimiento
  getList(){
    const List =  axios.get(`${this.urlApi}mensaje`);
    return List;
  }
  //TODO: Datos Generales
  // createDataGeneral(data: any){
  //   const Registro = axios.post(`${this.urlApi}encuestador` , data);
  //   return Registro
  // }
  createDataGeneral(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.urlApi}mensaje`, data)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
    }
  creareFormTracto(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.urlApi}mensaje`, data)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
}
  getById(id:number): Observable<any>{
    return this.http
    .get<any>(`${this.urlApi}mensaje/${id}`)
    .pipe(
      map((resp) =>{
        return resp;
      })
    )
  }

  //TOdo:Obtener Listado de conductores
  getAllDrivers(startDate:string , endDate:string):Observable<any>{
    return this.http
    .get<any>(`${this.urlApi}odoo-drivers`)
    .pipe(
      map((resp) =>{
        
        const driver = resp.result
        //TODO: Aplicamos filtro Conductores
        const DriverConductores =  Array.isArray(driver)
         ? driver.filter((conductor) => conductor.department_id && conductor.department_id.includes(8)):[];
        
        //TODO: Aplicamos Filtro por Compañias
        const CompaniaConductores = Array.isArray(DriverConductores)
        ? DriverConductores.filter((conductores) => conductores.address_id && conductores.address_id.includes(1)):[];
        return CompaniaConductores

        //TODO: Aplicar Filtros por Fechas
        const FilterFechas = CompaniaConductores.filter((driversDate) => {
           const conductorFecha = new Date(driversDate.first_contract_date);
           const startDateObj = new Date(startDate);
           const endDateObj = new Date(endDate);
          
           return conductorFecha >= startDateObj && conductorFecha <= endDateObj;
        });
        console.log('Filtrados por Fechas',  FilterFechas)
        return FilterFechas;



      })
    )

  }

  //TODO:Pintar Grafico de Costos Diarios
  getGraphicCostDaily(dateStart: string, dateEnd: string, monthly: string): Observable<any> {
    const url = `${this.urlApi}two-procedure?param1=${dateStart}&param2=${dateEnd}&param3=${monthly}`;
    console.log('URL de la solicitud:', url); // Agregar este console.log para ver la URL
  
    return this.http.get<any>(url)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }


   //TODO:Pintar Grafico de Costos Mensuales, Quimestrales , Trimestrales , Anuales
   getGraphicCostMonthly(dateStart: string, dateEnd: string, monthly: string): Observable<any> {
    const url = `${this.urlApi}/procedure?param1=${dateStart}&param2=${dateEnd}&param3=${monthly}`;
    console.log('URL de la solicitud:', url); // Agregar este console.log para ver la URL
  
    return this.http.get<any>(url)
      .pipe(
        map((resp) => {
          return resp[0].get_test_test;
        })
      );
  }
 


  //TODO: historico de Paquetes
  historicTours(userId: string, idRecord:string): Observable<any>{
    const urlAnt = `${this.urlApiAnt}getCallProcedure/getPackageAndPoint?idUser=${userId}&idRecord=${idRecord}`;
    console.log('Ant Backend Url', urlAnt);
    return this.http.get<any>(urlAnt)
      .pipe(
        map((resp) => {
          return resp;
        })
      )

  }


  //Vehiculos x Categoria
  vehiculos(){
    const urlodoo = `${this.urlApi}vehicle-vehicles/vehicles`;
    return this.http.get<any>(urlodoo)
      .pipe(
        map((resp) => {
          const result =  resp[0].vehicles_vehicles;
          return result;
        })
      )
  }

  vehicles_electric(){
    const urlodoo = `${this.urlApi}vehicle-vehicles/electrics`;
    return this.http.get<any>(urlodoo)
      .pipe(
        map((resp) => {
          const result = resp[0].vehicles_electrics;
          return result;
        })
      )
  }

  vehicles_special(){
    const urlodoo = `${this.urlApi}vehicle-vehicles/special`;
    return this.http.get<any>(urlodoo)
      .pipe(
        map((resp) => {
          const result = resp[0].vehicles_specials;
          return result;
        })
      )
  }

  vehicles_camions(){
    const urlodoo = `${this.urlApi}vehicle-vehicles/camions`;
    //console.log('Categoria Vehiculos', urlodoo);
    return this.http.get<any>(urlodoo)
      .pipe(
        map((resp) => {
          const result = resp[0].vehicles_camions;
          return result;
        })
      )
  }

  vehicles_bus(){
    const urlodoo = `${this.urlApi}vehicle-vehicles/autobuses`;
    return this.http.get<any>(urlodoo)
      .pipe(
        map((resp) => {
          const result  = resp[0].vehicles_bus;
          return result;
        })
      )
  }


  //Mantenimiento por Marca Grafico
  getmaintenancebybrand(){
    return this.http
    .get<any>(`${this.urlApi}/maintenance/maintenenacebybrandcount`)
    .pipe(
      map((resp) => {
        const data = resp[0].getallbrandservicecount;
        return data;
      })
    )
  }

  

  //TODO:Tipos de Mantenimiento
  gettypemaintenance(){
    return this.http
    .get<any>(`${this.urlApi}/maintenance/maintenenatypesporcentaje`)
    .pipe(
      map((resp) => {
        const data = resp[0].typemaintenance; 
        return data
      })
    )
  }

  loginUser(email:string , password:string, idRole:string):Observable<any>{
    const data = { email ,password, idRole}
    return this.http
    .post<any>(`${this.urlApi}/auth/login`, data)
    .pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  resetPassword(email:string, idRole:string):Observable<any>{
    const data = { email, idRole}
    return this.http
    .post<any>(`${this.urlApi}/auth/request-password-reset`, data)
    .pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  updatePassword(newPassword: string, token:string):Observable<any>{
    const data = { newPassword}
    return this.http
    .post<any>(`${this.urlApi}/auth/reset-password?token=${token}`, data)
    .pipe(
      map((resp) => {
        return resp;
      })
    );
  }
 





}