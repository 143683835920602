import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import type { Subscription } from 'rxjs'
import type { IconName } from '../../../shared/components/feather-icons/feather-icons.component'
import { profile } from 'console'
import { AuthService } from 'src/app/service/auth.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DialogLogoutComponent} from './dialog_logout/dialog-logout.component'
interface NavLinks {
  path: string
  label: string
  icon: IconName
}

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  expanded = false
  subscription: Subscription
  pathname = window.location.pathname

  navLinks: NavLinks[] = [
    { path: '/profile', label: 'Sidebar.Profile', icon: 'user' },
    { path: '/notifications', label: 'Sidebar.Notifications', icon: 'bell' },
    { path: '/', label: 'Sidebar.Dashboard', icon: 'copy' },
    { path: '/tours', label: 'Sidebar.Tours', icon: 'map' },
    { path: '/deliveries', label: 'Sidebar.Deliveries', icon: 'archive' },
    { path: '/vehicles', label: 'Sidebar.Vehicles', icon: 'truck' },
    { path: '/drivers', label: 'Sidebar.Drivers', icon: 'book' },
    { path: '/orders', label: 'Sidebar.Orders', icon: 'package' },
    { path: '/billing', label: 'Sidebar.Billing', icon: 'trello' },
    { path: '/maintenance', label: 'Sidebar.Maintenance', icon: 'settings' }
  ]

  constructor(
    private _router: Router,
    private auth_service: AuthService,
    private modalService: NgbModal,

    ) {}

  ngOnInit(): void {
    this.subscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pathname = window.location.pathname
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  logout(){

    const modalRef = this.modalService.open(  DialogLogoutComponent ,{
      size:'xl'
    })
    modalRef.result
    .then((result) => {
      if(result === 'Successful'){
        this.auth_service.logout();
      }
    })
    .catch((reason) => {
      console.log('Error al cerrar modal', reason);
    })
  }
}
