import { Injectable } from '@angular/core';
import { GetAllRecordsByFilterNodeResponse } from '../api/deliveries/recordsbyfilter';


@Injectable({
  providedIn: 'root'
})
export class DeliveriesService {
    private storedDataKey = 'rutasData';
  
    saveData(data: GetAllRecordsByFilterNodeResponse): void {
      localStorage.setItem(this.storedDataKey, JSON.stringify(data));
    }
  
    getData(): any[] {
      const data = localStorage.getItem(this.storedDataKey);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogout(): void {
        localStorage.removeItem(this.storedDataKey);
      }
}
