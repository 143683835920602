<div
  class="vehicle-option cursor-pointer border rounded-md px-4 py-4 text-center shadow-md hover:shadow-md"
  [ngClass]="{ 'bg-orange-500 text-white': isActive }"
  (click)="handleClick()"
>
  <img
    [src]="vehicleInfo?.imagePath"
    [alt]="vehicleInfo?.name?.en"
    class="w-10 mx-auto my-0"
  />
  <p class="break-words">{{ vehicleInfo?.name?.es }}</p>
</div>
