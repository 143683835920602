import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SharedModule } from './shared/shared.module'
import { AppRoutingModule } from './app-routing.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ToastrModule } from 'ngx-toastr'

// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core'

import { AdminGuard } from './shared/guard/admin.guard'
import { CookieService } from 'ngx-cookie-service'
import { OverlayModule } from '@angular/cdk/overlay'

//Services
import { maintenanceService } from './service/axios.service'
import { DriversService } from './service/drivers_location.sevice'
import { AuthService } from './service/auth.service'
import { GraphicsService } from './service/graphics.service'
import { DeliveriesService } from './service/deliveries.service'
import { VehicleService } from './service/vehicle.service'
import { DriversGraphicService} from './service/drivers.service'
import { MaintenanceGraphicsService} from './service/maintenance.service'
import { HomeGraphicService  } from './service/home.service'
import {  TourGraphicService} from './service/tour.service'


import { AppComponent } from './app.component'
import { HomeLayoutComponent } from './components/layout/home-layout/home-layout.component'
import { NgxMapboxGLModule } from 'ngx-mapbox-gl'
import { environment } from 'src/environments/environment';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  declarations: [AppComponent, HomeLayoutComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    //     // for HttpClient use:
    LoadingBarHttpClientModule,
    //     // for Router use:
    LoadingBarRouterModule,
    //     // for Core use:
    LoadingBarModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox_token,
    }),
  ],
  providers: [AdminGuard, CookieService, maintenanceService,
    DriversService, AuthService, GraphicsService,
    DeliveriesService, VehicleService , DriversGraphicService , MaintenanceGraphicsService , HomeGraphicService , TourGraphicService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
