<div class="custom-question-form px-4">
  <div
    class="flex flex-col justify-between py-8 bg-white border border-gray-200 rounded-md"
  >
    <div class="flex flex-col">
      <h1 class="text-lg text-center font-semibold text-[#0B1354]">
        Añade una pregunta personalizada
      </h1>
    </div>

    <div class="flex gap-8">
      <div class="mt-4 flex flex-col w-1/2">
        <label for="question" class="text-sm pb-4 font-medium text-gray-700"
          >Pregunta:</label
        >
        <textarea
          id="question"
          rows="4"
          class="w-full p-2 border border-orange-500 rounded-md focus:outline-none focus:border-orange-500"
          placeholder="Escribe tu pregunta aquí..."
          [(ngModel)]="question"
        ></textarea>
      </div>

      <div class="mt-4 flex flex-col w-1/2">
        <label for="answerType" class="text-sm pb-4 font-medium text-gray-700"
          >Tipo de respuesta:</label
        >
        <select
          id="answerType"
          class="w-full p-2 border rounded-md focus:outline-none focus:ring border-orange-500"
          [(ngModel)]="selectedAnswerType"
        >
          <option value="SI/NO">SI/NO</option>
          <option value="MULTIPLE_CHOICE">Opción múltiple</option>
          <option value="WRITTEN">Respuesta escrita</option>
        </select>
      </div>
    </div>

    <div
      class="gap-10 mt-4 flex flex-col"
      *ngIf="selectedAnswerType === 'MULTIPLE_CHOICE'"
    >
      <label for="answerOptions" class="text-sm font-medium pt-6 text-gray-700"
        >Opciones de respuesta:</label
      >
      <div class="flex flex-wrap -m-2">
        <div
          class="m-2 flex items-center"
          *ngFor="let option of answerOptions; let i = index"
        >
          <input
            type="text"
            id="answerOption{{ i }}"
            class="w-full p-2 border border-orange-500 rounded-md focus:outline-none focus:border-orange-500"
            [(ngModel)]="answerOptions[i]"
          />
          <button
            (click)="removeAnswerOption(i)"
            class="ml-2 p-1 text-red-500 hover:text-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <button
        (click)="addAnswerOption()"
        class="mt-2 p-2 border border-orange-500 rounded-md text-sm font-medium text-gray-700"
      >
        Añadir opción
      </button>
    </div>

    <div class="mt-4 flex flex-col" *ngIf="selectedAnswerType === 'WRITTEN'">
      <label for="writtenAnswer" class="text-sm font-medium text-gray-700"
        >Respuesta escrita:</label
      >
      <textarea
        id="writtenAnswer"
        rows="4"
        class="w-full p-2 border border-orange-500 rounded-md focus:outline-none focus:ring focus:ring-orange-500"
        [(ngModel)]="writtenAnswer"
      ></textarea>
    </div>

    <div class="mt-4 flex justify-end">
      <button
        class="px-5 py-4 border border-orange-500 rounded-md text-md text-gray-700"
      >
        Cancelar
      </button>
      <button
        class="ml-5 px-6 py-4 border border-orange-500 rounded-md text-md text-white bg-orange-500 hover:bg-orange-500"
      >
        Guardar
      </button>
    </div>
  </div>
</div>
