import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphicsService {
  private cachedDataSubject = new BehaviorSubject<any>(null);

  getChartData() {
    return this.cachedDataSubject.value;
  }

  setChartData(data: any) {
    this.cachedDataSubject.next({ ...data, timestamp: Date.now() });
  }
}
