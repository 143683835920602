import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceGraphicsService {
    private storedDataKey = 'maintenance';
    private dashboardMaintenance = 'dashboardMaintenance';
    private dashboardTotalSupplier = 'dashboardTotalSupplier';
    private dashboardMaintenanceCost = 'dashboardMaintenanceCost';
    private dashboardPending = 'dashboardPending';
    private dashboardHistoricCost = 'dashboardHistoricCost';
    private dashboardAverageRepair = 'dashboardAverageRepair';
    private dashboardMaintenanceByBrand = 'dashboardMaintenanceByBrand';
    private dashboardProblemVehicles = 'dashboardProblemVehicles';
    private kpiDashboardMaintenance = ' kpiDashboardMaintenace';
    private RegistrationsHistoryOfProblemsWithPriority = 'HistoryOfProblemsWithPriority';
    private RegistrationHistoryOfProblems = 'HistoryOfProblems';
    private kpiFinancesMaintenance = 'MaintenanceStateRunningFinances';
    private kpiFinancesTotalSupplier = 'TotalSupplierFinances';
    private kpiFinancesMaintenanceNewRunning = 'MaintenanceStateNewRunningFinances';
    private kpiFinancesCost = 'CostFinances';
    private kpiFinancesExpenditureHistory = 'ExpenditureHistoryFinances';
    private kpiFinancesAverageReapirsExpenditure = 'AverageReapirsExpenditureFinances';
    private kpiFinancesSumOfInativeDays = 'SumOfInativeDaysFinances';
    private kpiFinancesMeanTimeBetweenFailures = 'MeanTimeBetweenFailuresFinances';
    private CostReapir = 'CostRepair';
    private AvgReapir = 'AvgRepair';
    private nroVehiclesByCriticidad = 'NroVehiclesByCriticidad';
    private nroProblemsByPriority = 'nroProblemsByPriority';
    private nroProblemsBySection = 'nroProblemsBySection';
    private totalMaintenanceByStateGraveandMuyGrave = 'totalMaintenanceByStateGraveandMuyGrave';
  
    saveData(data: any[]): void {
      localStorage.setItem(this.storedDataKey, JSON.stringify(data));
    }
  
    getData(): any[] {
      const data = localStorage.getItem(this.storedDataKey);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogout(): void {
        localStorage.removeItem(this.storedDataKey);
        localStorage.removeItem(this.dashboardMaintenance);
        localStorage.removeItem(this.dashboardTotalSupplier);
        localStorage.removeItem(this.dashboardMaintenanceCost);
        localStorage.removeItem(this.dashboardPending);
        localStorage.removeItem(this.dashboardHistoricCost);
        localStorage.removeItem(this.dashboardAverageRepair);
        localStorage.removeItem(this.dashboardMaintenanceByBrand);
        localStorage.removeItem(this.dashboardProblemVehicles);
        localStorage.removeItem(this.kpiDashboardMaintenance);
        localStorage.removeItem(this.RegistrationsHistoryOfProblemsWithPriority);
        localStorage.removeItem(this.RegistrationHistoryOfProblems);
        localStorage.removeItem(this.kpiFinancesMaintenance);
        localStorage.removeItem(this.kpiFinancesTotalSupplier);
        localStorage.removeItem(this.kpiFinancesMaintenanceNewRunning);
        localStorage.removeItem(this.kpiFinancesCost);
        localStorage.removeItem(this.kpiFinancesExpenditureHistory);
        localStorage.removeItem(this.kpiFinancesAverageReapirsExpenditure);
        localStorage.removeItem(this.kpiFinancesSumOfInativeDays);
        localStorage.removeItem(this.kpiFinancesMeanTimeBetweenFailures);
        localStorage.removeItem(this.CostReapir);
        localStorage.removeItem(this.AvgReapir);
        localStorage.removeItem(this.nroVehiclesByCriticidad);
        localStorage.removeItem(this.nroProblemsByPriority);
    }

    saveDataDashboardMaintenance(data: any[]): void {
      localStorage.setItem(this.dashboardMaintenance, JSON.stringify(data));
    }
  
    getDataDashboardMaintenance(): any[] {
      const data = localStorage.getItem(this.dashboardMaintenance);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardMaintenance(): void {
        localStorage.removeItem(this.dashboardMaintenance);
    }

    saveDataDashboardTotalSupplier(data: any[]): void {
      localStorage.setItem(this.dashboardTotalSupplier, JSON.stringify(data));
    }
  
    getDataDashboardTotalSupplier(): any[] {
      const data = localStorage.getItem(this.dashboardTotalSupplier);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardTotalSupplier(): void {
        localStorage.removeItem(this.dashboardTotalSupplier);
    }

    saveDataDashboardMaintenanceCost(data: any[]): void {
      localStorage.setItem(this.dashboardMaintenanceCost, JSON.stringify(data));
    }
  
    getDataDashboardMaintenanceCost(): any[] {
      const data = localStorage.getItem(this.dashboardMaintenanceCost);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardMaintenanceCost(): void {
        localStorage.removeItem(this.dashboardMaintenanceCost);
    }

    saveDataDashboardPending(data: any[]): void {
      localStorage.setItem(this.dashboardPending, JSON.stringify(data));
    }
  
    getDataDashboardPending(): any[] {
      const data = localStorage.getItem(this.dashboardPending);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardPending(): void {
        localStorage.removeItem(this.dashboardPending);
    }

    saveDataDashboardHistoricCost(data: any[]): void {
      localStorage.setItem(this.dashboardHistoricCost, JSON.stringify(data));
    }
  
    getDataDashboardHistoricCost(): any[] {
      const data = localStorage.getItem(this.dashboardHistoricCost);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardHistoricCost(): void {
        localStorage.removeItem(this.dashboardHistoricCost);
    }

    saveDataDashboardAverageRepair(data: any[]): void {
      localStorage.setItem(this.dashboardAverageRepair, JSON.stringify(data));
    }
  
    getDataDashboardAverageRepair(): any[] {
      const data = localStorage.getItem(this.dashboardAverageRepair);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardAverageRepair(): void {
        localStorage.removeItem(this.dashboardAverageRepair);
    }

    saveDataDashboardMaintenanceByBrand(data: any[]): void {
      localStorage.setItem(this.dashboardMaintenanceByBrand, JSON.stringify(data));
    }
  
    getDataDashboardMaintenanceByBrand(): any[] {
      const data = localStorage.getItem(this.dashboardMaintenanceByBrand);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardMaintenanceByBrand(): void {
        localStorage.removeItem(this.dashboardMaintenanceByBrand);
    }

    saveDataDashboardProblemVehicles(data: any[]): void {
      localStorage.setItem(this.dashboardProblemVehicles, JSON.stringify(data));
    }
  
    getDataDashboardProblemVehicles(): any[] {
      const data = localStorage.getItem(this.dashboardProblemVehicles);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutDashboardProblemVehicles(): void {
        localStorage.removeItem(this.dashboardProblemVehicles);
    }

    //KPIS
    saveDatakpiDashboardMaintenance(data: any[]): void {
      localStorage.setItem(this.kpiDashboardMaintenance, JSON.stringify(data));
    }
  
    getDatakpiDashboardMaintenance(): any[] {
      const data = localStorage.getItem(this.kpiDashboardMaintenance);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiDashboardMaintenance(): void {
        localStorage.removeItem(this.kpiDashboardMaintenance);
    }

    saveRegistrationsHistoryOfProblemsWithPriority(data: any[]): void {
      localStorage.setItem(this.RegistrationsHistoryOfProblemsWithPriority, JSON.stringify(data));
    }
  
    getRegistrationsHistoryOfProblemsWithPriority(): any[] {
      const data = localStorage.getItem(this.RegistrationsHistoryOfProblemsWithPriority);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutRegistrationsHistoryOfProblemsWithPriority(): void {
        localStorage.removeItem(this.RegistrationsHistoryOfProblemsWithPriority);
    }

    savekpiFinancesMaintenance(data: any[]): void {
      localStorage.setItem(this.kpiFinancesMaintenance, JSON.stringify(data));
    }
  
    getkpiFinancesMaintenance(): any[] {
      const data = localStorage.getItem(this.kpiFinancesMaintenance);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesMaintenance(): void {
        localStorage.removeItem(this.kpiFinancesMaintenance);
    }
   
    savekpiFinancesTotalSupplier(data: any[]): void {
      localStorage.setItem(this.kpiFinancesTotalSupplier, JSON.stringify(data));
    }
  
    getkpiFinancesTotalSupplier(): any[] {
      const data = localStorage.getItem(this.kpiFinancesTotalSupplier);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesTotalSupplier(): void {
        localStorage.removeItem(this.kpiFinancesTotalSupplier);
    }
    savekpiFinancesMaintenanceNewRunning(data: any[]): void {
      localStorage.setItem(this.kpiFinancesMaintenanceNewRunning, JSON.stringify(data));
    }
  
    getkpiFinancesMaintenanceNewRunning(): any[] {
      const data = localStorage.getItem(this.kpiFinancesMaintenanceNewRunning);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesMaintenanceNewRunning(): void {
        localStorage.removeItem(this.kpiFinancesMaintenanceNewRunning);
    }

    savekpiFinancesCost(data: any[]): void {
      localStorage.setItem(this.kpiFinancesCost, JSON.stringify(data));
    }
  
    getkpiFinancesCost(): any[] {
      const data = localStorage.getItem(this.kpiFinancesCost);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesCost(): void {
        localStorage.removeItem(this.kpiFinancesCost);
    }

    savekpiFinancesExpenditureHistory(data: any[]): void {
      localStorage.setItem(this.kpiFinancesExpenditureHistory, JSON.stringify(data));
    }
  
    getkpiFinancesExpenditureHistory(): any[] {
      const data = localStorage.getItem(this.kpiFinancesExpenditureHistory);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesExpenditureHistory(): void {
        localStorage.removeItem(this.kpiFinancesExpenditureHistory);
    }

    savekpiFinancesAverageReapirsExpenditure(data: any[]): void {
      localStorage.setItem(this.kpiFinancesAverageReapirsExpenditure, JSON.stringify(data));
    }
  
    getkpiFinancesAverageReapirsExpenditure(): any[] {
      const data = localStorage.getItem(this.kpiFinancesAverageReapirsExpenditure);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesAverageReapirsExpenditure(): void {
        localStorage.removeItem(this.kpiFinancesAverageReapirsExpenditure);
    }

    savekpiFinancesSumOfInativeDays(data: any[]): void {
      localStorage.setItem(this.kpiFinancesSumOfInativeDays, JSON.stringify(data));
    }
  
    getkpiFinancesSumOfInativeDays(): any[] {
      const data = localStorage.getItem(this.kpiFinancesSumOfInativeDays);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesSumOfInativeDays(): void {
        localStorage.removeItem(this.kpiFinancesSumOfInativeDays);
    }

    savekpiFinancesMeanTimeBetweenFailures(data: any[]): void {
      localStorage.setItem(this.kpiFinancesMeanTimeBetweenFailures, JSON.stringify(data));
    }
  
    getkpiFinancesMeanTimeBetweenFailures(): any[] {
      const data = localStorage.getItem(this.kpiFinancesMeanTimeBetweenFailures);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutkpiFinancesMeanTimeBetweenFailures(): void {
        localStorage.removeItem(this.kpiFinancesMeanTimeBetweenFailures);
    }

    saveCostReapir(data: any[]): void {
      localStorage.setItem(this.CostReapir, JSON.stringify(data));
    }
  
    getCostReapir(): any[] {
      const data = localStorage.getItem(this.CostReapir);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutCostReapir(): void {
        localStorage.removeItem(this.CostReapir);
    }

    saveAvgReapir(data: any[]): void {
      localStorage.setItem(this.AvgReapir, JSON.stringify(data));
    }
  
    getAvgReapir(): any[] {
      const data = localStorage.getItem(this.AvgReapir);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutAvgReapir(): void {
        localStorage.removeItem(this.AvgReapir);
    }

    saveRegistrationHistoryOfProblems(data: any[]): void {
      localStorage.setItem(this.RegistrationHistoryOfProblems, JSON.stringify(data));
    }
  
    getRegistrationHistoryOfProblems(): any[] {
      const data = localStorage.getItem(this.RegistrationHistoryOfProblems);
      return data ? JSON.parse(data) : [];
    }

    clearDataOnLogoutRegistrationHistoryOfProblems(): void {
        localStorage.removeItem(this.RegistrationHistoryOfProblems);
    }


    saveNroVehiclesByCriticidad(data: any[]): void {
      localStorage.setItem(this.nroVehiclesByCriticidad, JSON.stringify(data));
    }
  
    getNroVehiclesByCriticidad(): any[] {
      const data = localStorage.getItem(this.nroVehiclesByCriticidad);
      return data ? JSON.parse(data) : [];
    }

    clearDataNroVehiclesByCriticidad(): void {
        localStorage.removeItem(this.nroVehiclesByCriticidad);
    }



    savegetProblemsByPriority(data: any[]): void {
      localStorage.setItem(this.nroProblemsByPriority, JSON.stringify(data));
    }
  
    getProblemsByPriority(): any[] {
      const data = localStorage.getItem(this.nroProblemsByPriority);
      return data ? JSON.parse(data) : [];
    }

    clearDatagetProblemsByPriority(): void {
        localStorage.removeItem(this.nroProblemsByPriority);
    }


    saveProblemsBySection(data: any[]): void {
      localStorage.setItem(this.nroProblemsBySection, JSON.stringify(data));
    }
  
    getProblemsBySection(): any[] {
      const data = localStorage.getItem(this.nroProblemsBySection);
      return data ? JSON.parse(data) : [];
    }

    clearDatagetProblemsBySection(): void {
        localStorage.removeItem(this.nroProblemsBySection);
    }

    savetotalMaintenanceByStateGraveandMuyGrave(data: any[]): void {
      localStorage.setItem(this.totalMaintenanceByStateGraveandMuyGrave, JSON.stringify(data));
    }
  
    getotalMaintenanceByStateGraveandMuyGrave(): any[] {
      const data = localStorage.getItem(this.totalMaintenanceByStateGraveandMuyGrave);
      return data ? JSON.parse(data) : [];
    }

    clearDatatotalMaintenanceByStateGraveandMuyGrave(): void {
        localStorage.removeItem(this.totalMaintenanceByStateGraveandMuyGrave);
    }



    
}
