<div
  class="flex flex-row items-center justify-center h-full px-2 cursor-pointer gap-3"
  [ngClass]="{ 'text-orange-500': isActive, 'text-stone-500': !isActive }"
>
  <img
    [src]="getImagePath(vehicleType)"
    [alt]="vehicleType"
    class="w-10 h-10 m-0"
  />
  <span class="font-bold text-stone-500 text-center text-sm">
    {{ vehicleModel }}
  </span>
  <p class="text-sm font-bold text-stone-500 text-center">{{ vehiclePlate }}</p>
</div>
